import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "../history";
import "react-table/react-table.css";
import { Spinner } from "react-rainbow-components";
import './styles.css';

const axios = require("axios").default;

axios.interceptors.request.use(async (request) => {
  if (
    request.url.includes("/signin/") ||
    request.url.includes("/changepassword/")
  ) {
    return request;
  } else {
    request.headers["x-mart48-admin-access-token"] = localStorage.getItem(
      "accessToken"
    );
  }
  return request;
});

const loading = () => (
  <div className="rainbow-position_relative rainbow-p-vertical_xx-large">
    <Spinner size="large" type="arc" variant="brand" />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() =>
  import("../Containers/DefaultLayout/index")
);
const SignIn = React.lazy(() => import("../sections/Public/SignIn/SignIn"));
const SignUp = React.lazy(() => import("../sections/Public/Signup/Signup"));
const Forgot = React.lazy(() =>
  import("../sections/Public/SignIn/ChangePassword")
);
const ProtectedRoute = React.lazy(() => import("../ProtectedRoute"));
class App extends Component {
  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Redirect from="/" exact to="/signin" /> */}
            <Route path="/signin" name="Login Page" component={SignIn} />
            <Route path="/signup" name="Signup Page" component={SignUp} />
            <Route
              path="/changepassword"
              name="Forgot Password"
              component={Forgot}
            />
            <ProtectedRoute path="/dashboard" component={DefaultLayout} />
            <ProtectedRoute path="/" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
